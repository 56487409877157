import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import H2 from 'components/new/typography/h2'
import LocationsMapSearch from 'components/locations-map/locations-map-search'
import { Seo } from '~/components/seo'

const breadcrumbs = [
  {
    name: 'Hutson Inc',
    displayName: 'Home',
    link: '/',
  },
  {
    name: 'Service',
    link: '/service/',
  },
  {
    name: 'Ag Equipment Service Programs',
    link: '/service/ag-maintenance-agreements/',
  },
  {
    name: 'Combine Service Programs',
    link: '/service/ag-maintenance-agreements/combines/',
  },
]

const CombinesSMAPage = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      heroImage,
    },
  } = props
  return (
    <Layout>
      <Seo title={`Combine Service Programs`} breadcrumbs={breadcrumbs} />

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Combine Service Programs'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <div style={{ height: '70vh', minHeight: '250px' }}>
          <iframe
            src='https://player.flipsnack.com?hash=NTk3NUFCREQ3NUUrdWs4d3A5cGQxag==&p=2'
            title='Ag Service Maintenance Agreements'
            width='100%'
            height='100%'
            seamless='seamless'
            scrolling='no'
            frameBorder='0'
            allowFullScreen
            allow='autoplay; clipboard-read; clipboard-write'
          ></iframe>
        </div>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2>Contact your nearest service shop to learn more</H2>
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
      </LightSection>
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

export const pageQuery = graphql`
  query combinesSMAPageQuery {
    heroImage: file(relativePath: { eq: "service/maintenance-agreements/combine-sma-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityLocation(
      filter: { retailLocation: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
  }
`

export default CombinesSMAPage
